import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap";
import PageWrapper from "../../../components/PageWrapper";
import styled from "styled-components";
import SEO from "../../../components/seo";
import BackButton from "../../../components/Core/BackButton";
import PageTitle from "../../../components/Core/PageTitle";
import NoAuthRedirect from "../../../components/Core/NoAuthRedirect";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

const Content = styled.div`

    $ a:hover {
        color: silver;
    }

    & h2 {
        margin: 1.5rem 0;
    }
`

const BlogArticle = ({data, location}) => {
    const headerConfig = {
        paddingBottom: 10,
    };

    return (
        <>  
            <UnauthenticatedTemplate>
                <NoAuthRedirect location={location} />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <PageWrapper headerConfig={headerConfig}>
                    <SEO title="Recent experience - Sangeetha Balasubramanian" />
                    <Content>
                        <BackButton />
                        <Container className="">
                            <Row className="">
                                <Col className="col-lg-2"></Col>
                                <Col className="col-lg-6">
                                    <PageTitle
                                        title="Sangeetha Balasubramanian shares her experience"
                                        align="left"
                                    />
                                </Col>
                            </Row>
                            <Row className="">
                                <Col className="col-lg-2 text-right">
                                    <div className="mb-4 border-top pt-7">
                                        {/* Recent experience */}
                                    </div>
                                    <div>
                                        {/* Last updated<br /> */}
                                        {/* 2 Jan, 2022 */}
                                    </div>
                                </Col>
                                <Col className="col-lg-6">
                                    <StaticImage
                                        src="photo_sangeetha_park.jpg"
                                        alt="Photo of Sangeetha sitting in the park, smiling"
                                        placeholder="blurred"
                                        layout="fixed"
                                        quality={100}
                                        width={480}
                                        style={{maxWidth: '100%'}}
                                        className="mb-10"
                                    />
                                    <p>
                                        My name is Sangeetha Balasubramanian and I work as Enterprise Solutions Manager at COFRA Services. 
                                    </p>
                                    <p>
                                        I signed up as a mentor to play my part in helping to shape the leaders of tomorrow. Being a mentor also offers great self-reflection opportunities and reminds me of what I enjoy about my profession. One of the underestimated aspects of being a mentor is the “feel good” factor and I would say that there is nothing more rewarding than knowing you are making a difference to someone else’s life. And that works both ways, with both the mentor and mentee benefitting from the process. 
                                    </p>
                                    <p>
                                        On a personal level, I appreciate the opportunity to gain deeper insights about myself whilst developing my communications and mentoring skills. Being a mentor serves as a good learning opportunity to expand my knowledge and grow in confidence, whilst also expanding my network. It gives me an insight into what goes on in other parts of the company and this equips me to make sounder, more holistic decisions.
                                    </p> 
                                    <p>
                                        I would recommend everyone to sign up for the mentorship experience – as a mentor or as a mentee - as this will help you gain new perspectives, fresh ideas and an understanding of how the world looks through someone else’s eyes. 
                                    </p>
                                    <p>
                                        For anyone who is looking forward to be being a mentor, here are my top 5 tips: 
                                    </p>
                                    <ol>
                                        <li>
                                            <b>Listen actively</b> <br />
                                            <p>Don’t fall into the trap of doing more talking than listening. By listening, you demonstrate to your mentee that their concerns have been heard and understood. </p>
                                        </li>                                    
                                        <li>
                                            <b>Be encouraging, not judgmental</b> <br />
                                            <p>Sharing your experience is a great way to send a message, along with sincere positive feedback.</p>
                                        </li> 
                                        <li>
                                            <b>Show empathy</b> <br />
                                            <p>Try to understand how your mentee is feeling and how to best approach guiding them.</p>
                                        </li>
                                        <li>
                                            <b>Let your mentee make the decisions</b> <br />
                                            <p>Mentorship is about guiding and empowering, not directing.</p>
                                        </li>
                                        <li>
                                            <b>Help With the Little Things</b> <br />
                                            <p>Mentors often want to provide big insights and give general advice. Sometimes helping with the little things can make the biggest difference!</p>
                                        </li>
                                    </ol>
                                </Col>
                            </Row>
                        </Container>
                    </Content>
                </PageWrapper>
            </AuthenticatedTemplate>
        </>
    )
}

export default BlogArticle;
